<template>
  <div>
    <Spinner :isActive="isLoading"></Spinner>
    <Notification></Notification>
    <ApproveModal></ApproveModal>
    <Menu :items="menuItems"></Menu>
    <div class="router-view mb-3">
      <router-view/>
    </div>
  </div>
</template>

<script>

import Spinner from './components/Spinner';
import Notification from './components/Notification';
import ApproveModal from './components/modals/ApproveModal';
import Menu from './components/Menu';
import { MenuItemTypes } from './consts/menuItemTypes';
import { gameHub } from './services/hubService';

export default {
  data () {
    return {
      content: {
        gameArea: 'Game Area',
        login: 'Login',
        registration: 'Registration',
        products: 'Products',
        about: 'About',
        profile: 'Profile',
        logout: 'Logout',
        userProducts: 'My products',
        admin: 'Admin',
      }
    }
  },
  components: {
    Spinner,
    Notification,
    ApproveModal,
    Menu,
  },
  created() {
    this.initConnection();
  },
  computed: {
    isLoading() {
      return this.$store.state.isLoading;
    },
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    getTokenAccess() {
      return this.$store.getters.getTokenAccess;
    },
    menuItems() {
      return [
        {
          type: MenuItemTypes.route,
          hidden: !this.isAuthorized,
          name: this.content.gameArea,
          route: '/game-area',
        },
        {
          type: MenuItemTypes.route,
          hidden: this.isAuthorized,
          name: this.content.login,
          route: '/login',
        },
        {
          type: MenuItemTypes.route,
          hidden: this.isAuthorized,
          name: this.content.registration,
          route: '/registration',
        },
        {
          type: MenuItemTypes.action,
          hidden: false,
          name: this.content.profile,
          action: () => {
            this.profile();
          },
        },
        {
          type: MenuItemTypes.action,
          hidden: !this.isAuthorized,
          name: this.content.logout,
          action: () => {
            this.logout();
          },
        },
      ];
    },
  },
  methods: {
    profile() {
      this.$router.push('/profile');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    initConnection() {
      if (!this.isAuthorized) return;
      gameHub.connectionCreate();
    },
  },
}
</script>

<style lang="scss">

@import 'src/assets/css/global.scss';

.router-view {
  padding-top: 85px;
}
</style>
