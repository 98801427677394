<template>
  <div>
    <div>
      <span>Decks</span>
      <span class="ml-2">{{ userDecksCount }}/{{ gameDeckNumber }}</span>
    </div>
    <div class="decks">
      <div v-if="userDecks" class="user-decks">
        <div v-for="(item, index) in userDecks" :key="index">
          <div class="deck-block block-default mt-3">
            <div>
              <span>{{ item.name }}</span>
            </div>
            <div>
              <span>
                <img @click="updateDeck(item)" class="deck-icon" src="@/assets/icons/pen-square-icon.svg">
                <img @click="removeDeck(item)" class="deck-icon ml-3" src="@/assets/icons/trash-icon.svg">
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="deck-icons-block">
        <img v-show="isDeckCreateActive && !isDeckMenuVisible" @click="openDeckMenu" class="deck-create-icon mt-3" src="@/assets/icons/plus-square-icon.svg">
        <img v-show="isDeckMenuVisible" @click="closeDeckMenu" class="deck-create-icon mt-3" src="@/assets/icons/close-gray-icon.svg">
      </div>
      <div v-if="isDeckMenuVisible" class="deck-menu">
        <div v-if="isDeckUpdateActive" class="mt-3">
          <span class="bolder deck-action">[UPDATE]</span>
        </div>
        <div v-else class="mt-3">
          <span class="bolder deck-action">[CREATE]</span>
        </div>
        <div class="deck-name-box mt-3">
          <InputBox
            :modelValue="deckName"
            :content="deckName"
            @update:modelValue="deckName = $event"
            title="Deck name"
            type="text"
            :isError="deckNameError"
            :messageError="deckNameErrorMessage">
          </InputBox>
        </div>
        <div class="mt-3">
          <span>Active </span>
          <span>
            <CheckBox 
              :modelValue="isDeckActive"
              :content="isDeckActive"
              @update:modelValue="isDeckActive = $event">
            </CheckBox>
          </span>
        </div>
        <div class="mt-3">
          <button
            @click="saveDeck"
            :disabled="isDeckSaveDisabled"
            type="button" class="btn btn-success button-default">Save</button>
        </div>
        <div class="deck-cards-box col-sm-12 mt-3">
          <div class="row">
            <div class="col-sm-6 p-0 card-preview-list-box">
              <div>
                <span>Deck cards</span>
                <span class="ml-2">{{ deckCardsNumber }} / {{ gameCardsNumber }}</span>
                <span class="ml-2">
                  <img v-show="isDeckCardsExists" @click="clearDeckCards" class="clear-deck-cards-icon" src="@/assets/icons/trash-icon.svg">
                </span>
              </div>
              <div class="card-preview-list">
                <div 
                  v-for="(item, index) in deckCards" :key="index"
                  class="card-preview-item">
                  <CardPreview
                    @click="clickCardPreview(item, index)"
                    :card="item">
                  </CardPreview>
                </div>
              </div>
            </div>
            <div class="col-sm-6 p-0 card-list-box">
              <div>
                <span>Cards</span>
              </div>
              <div class="card-list scrollbar-base">
                <div
                  v-for="(item, index) in cards" :key="index"
                  :class="{ 'mb-3': index + 1 === cards.length }"
                  class="card-item ml-3">
                  <Card
                    :ref="getCardRef(item.type)"
                    :card="item"
                    @selectCard="selectCard(item)">
                  </Card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputBox from '../../components/InputBox.vue';
import CheckBox from '../../components/CheckBox.vue';
import Card from './Card';
import CardPreview from './CardPreview';

export default {
  data () {
    return {
      cardRef: 'Card',
      gameDeckNumber: 2,
      gameCardsNumber: 4,
      gameDeckNameLengthMax: 15,
      cards: [],
      isDeckMenuVisible: false,
      deckId: null,
      deckName: '',
      deckCards: [],
      isDeckActive: false,

      content: {
        deckNameLengthError: 'Deck name length must be between 1 and 15',
        deckNameDuplicateError: 'Duplicates are not allowed',
      },
    }
  },
  components: {
    InputBox,
    CheckBox,
    Card,
    CardPreview,
  },
  props: {
  },
  computed: {
    getCards() {
      return this.$store.getters.getCards;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
    userDecks() {
      return this.getUser.decks;
    },
    userDecksCount() {
      return this.getUser.decks ? this.getUser.decks.length : 0;
    },
    isDeckCreateActive() {
      return this.userDecksCount < this.gameDeckNumber;
    },
    isDeckUpdateActive() {
      return this.deckId !== null;
    },
    deckNameLengthError() {
      if (this.deckName.length === 0 || this.deckName.length > this.gameDeckNameLengthMax) {
        return true;
      }
      return false;
    },
    deckNameDuplicateError() {
      if (this.deckName.length > 0 && this.userDecksCount > 0 && !this.isDeckUpdateActive) {
        return this.userDecks.some(x => x.name === this.deckName);
      }
      return false;
    },
    deckNameError() {
      return this.deckNameLengthError || this.deckNameDuplicateError;
    },
    deckNameErrorMessage() {
      if (this.deckNameLengthError) {
        return this.content.deckNameLengthError;
      } else if (this.deckNameDuplicateError) {
        return this.content.deckNameDuplicateError;
      }
      return null;
    },
    deckCardsNumber() {
      return this.deckCards.length;
    },
    isDeckCardsMax() {
      return this.deckCards.length === this.gameCardsNumber;
    },
    isDeckCardsExists() {
      return this.deckCards.length > 0;
    },
    isDeckSaveDisabled() {
      return this.deckNameError || !this.isDeckCardsMax || (!this.isDeckCreateActive && !this.isDeckUpdateActive);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.cards = this.getCards;
    },
    openDeckMenu() {
      this.resetDeckMenu();
      this.isDeckMenuVisible = true;
    },
    closeDeckMenu() {
      this.resetDeckMenu();
      this.isDeckMenuVisible = false;
    },
    selectCard(card) {
      if (this.isDeckCardsMax) return;
      const ref = this.getCardRef(card.type);
      this.$refs[ref].increment();
      this.deckCards.push(card);
    },
    clearDeckCards() {
      this.deckCards = [];
      for (let i = 0; i < this.cards.length; i++) {
        const ref = this.getCardRef(this.cards[i].type);
        this.$refs[ref].clear();
      }
    },
    getCardRef(type) {
      return this.cardRef + type;
    },
    clickCardPreview(card, index) {
      const ref = this.getCardRef(card.type);
      this.$refs[ref].decrement();
      this.deckCards.splice(index, 1);
    },
    resetDeckMenu() {
      this.deckName = '';
      this.deckId = null,
      this.deckCards = [];
      this.isDeckActive = false;
    },
    saveDeck() {
      var cardIds = this.deckCards.map(x => x.id);
      let deck = {
        name: this.deckName,
        isActive: this.isDeckActive,
        cardIds, 
      };
      if (this.deckId) {
        deck.id = this.deckId;
        this.$emit('updateDeck', deck);
      } else {
        this.$emit('createDeck', deck);
      }
    },
    updateDeck(deck) {
      this.openDeckMenu();
      this.deckId = deck.id;
      this.deckName = deck.name;
      this.isDeckActive = deck.isActive;
      for (let i = 0; i < deck.deckCards.length; i++) {
        const deckCard = deck.deckCards[i];
        const card = this.cards.find(x => x.id === deckCard.cardId);
        this.deckCards.push(card);
      }
    },
    removeDeck(deck) {
      const model = { deckId: deck.id };
      this.$emit('removeDeck', model);
    },
  },
}
</script>

<style lang="scss" scoped>
.decks {
  position: relative;
  .user-decks {
    position: relative;
    .deck-block {
      max-width: 300px;
      position: relative;
      padding: 5px 10px 5px 10px;
      border-radius: 5px;
      @media screen and (min-width: 290px) {
        display: flex;
        justify-content: space-between;
      }
      .deck-icon {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }
  .deck-icons-block {
    position: relative;
    .deck-create-icon {
      cursor: pointer;
      width: 25px;
      height: 25px;
    }
  }
  .deck-menu {
    position: relative;
    .deck-action {
      color: #28a745;
    }
    .deck-name-box {
      max-width: 300px;
    }
    .active-checkbox {
      width: 15px;
      cursor: pointer;
    }
    .deck-cards-box {
      position: relative;
      .card-preview-list-box {
        .clear-deck-cards-icon {
          cursor: pointer;
          width: 25px;
          height: 25px;
        }
        .card-preview-list {
          position: relative;
          max-height: 250px;
          .card-preview-item {
            margin-top: 15px;
            max-width: 200px;
          }
        }
      }
      .card-list-box {
        @media screen and (max-width: 576px) {
          margin-top: 15px;
        }
        .card-list {
          position: relative;
          max-height: 250px;
          .card-item {
            margin-top: 15px;
            max-width: 200px;
          }
        }
      }
    }
  }
}
</style>
