<template>
  <div
    v-if="card"
    @click="selectCard"
    class="card-block block-default noselect">
    <div class="card-block-item text-center">
      <span class="bolder">{{ cardName }} {{ count }}/{{ gameCardsRepeatNumber }}</span>
    </div>
    <div class="card-info mt-3 text-left">
      <div class="card-info-main">
        <div class="card-block-item">
          <span>Health: {{ card.health }}</span>
        </div>
        <div class="card-block-item">
          <span>Armor: {{ card.armor }}</span>
        </div>
        <div class="card-block-item">
          <span>Damage: {{ card.damage }}</span>
        </div>
        <div class="card-block-item">
          <span>Restoration: {{ card.restoration }}</span>
        </div>
      </div>
      <div class="card-info-update">
        <div class="card-block-item">
          <span>&nbsp;&nbsp;[+{{ card.healthUpdate }}]</span>
        </div>
        <div class="card-block-item">
          <span>&nbsp;&nbsp;[+{{ card.armorUpdate }}]</span>
        </div>
        <div class="card-block-item">
          <span>&nbsp;&nbsp;[+{{ card.damageUpdate }}]</span>
        </div>
        <div class="card-block-item">
          <span>&nbsp;&nbsp;[+{{ card.restorationUpdate }}]</span>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import { GetGameCardName } from '../../consts/gameCardTypes';

export default {
  data () {
    return {
      gameCardsRepeatNumber: 3,
      count: 0,
    }
  },
  components: {
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cardName() {
      if (this.card) {
        return GetGameCardName(this.card.type);
      }
      return null;
    },
  },
  methods: {
    increment() {
      if (this.count < this.gameCardsRepeatNumber) {
        this.count++;
      }
    },
    decrement() {
      if (this.count > 0) {
        this.count--;
      }
    },
    clear() {
      this.count = 0;
    },
    selectCard() {
      if (this.count < this.gameCardsRepeatNumber)  {
        this.$emit('selectCard', this.card);
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.card-block {
  position: relative;
  padding: 20px;
  border-radius: 10px;
  cursor: pointer;
  .card-info {
    display: inline-block;
    .card-block-item {
      position: relative;
    }
    .card-info-main {
      display: inline-block;
    }
    .card-info-update {
      display: inline-block;
    }
  }
}
</style>
