<template>
  <div>
    <div v-if="user" class="user-block">
      <div class="user-block-item">
        <span>{{ user.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {

    }
  },
  components: {
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.user-block {
  position: relative;
  .user-block-item {
    position: relative;
  }
}
</style>
