import api from './httpService';

export const userController = {
  createUserHttpPost: function(email, name, password) {
    return api(`user/create`)
      .post({
        email,
        name,
        password,
      });
  },
  sendNewRegistrationCodeHttpPost: function(email) {
    return api(`user/send-new-registration-code`)
      .post({
        email,
      });
  },
  userActivationHttpPost: function(email, code) {
    return api(`user/user-activation`)
      .post({
        email,
        code,
      });
  },
  loginUserHttpPost: function(email, password) {
    return api(`user/login`)
      .post({
        email,
        password,
      });
  },
  passwordChangeRequestHttpPost: function(email) {
    return api(`user/password-change-request`)
      .post({
        email,
      });
  },
  passwordChangeApproveHttpPost: function(email, password, code) {
    return api(`user/password-change-approve`)
      .post({
        email,
        password,
        code,
      });
  },
  getUserPublicHttpGet: function(userId) {
    return api(`user/get-user-public-${userId}`)
      .get();
  },
  getUserHttpGet: function() {
    return api(`user/get-user`)
      .get();
  },
  refreshTokenHttpPost: function() {
    return api(`user/token-refresh`, null, true)
      .post();
  },
  deleteUserAccountHttpDelete: function() {
    return api(`user/delete-user-account`)
      .delete();
  },
};

export const gameController = {
};
