<template>
  <div class="card-block block-default noselect">
    <div>
      <span class="ml-2">{{ cardName }}</span>
    </div>
  </div>
</template>

<script>

import { GetGameCardName } from '../../consts/gameCardTypes';

export default {
  data () {
    return {
    }
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  computed: {
    cardName() {
      if (this.card) {
        return GetGameCardName(this.card.type);
      }
      return null;
    },
  },
  methods: {
  },
}
</script>

<style lang="scss" scoped>
.card-block {
  position: relative;
  padding: 5px;
  border-radius: 5px;
  cursor: pointer;
}
</style>
