<template>
  <ModalBase ref="GameCardUpdateModal">
    <div class="modal-body text-center">
      <div class="text-center mt-5 mb-5">
        <GameCardUpdate
          :card="card"
          class="game-card-item">
        </GameCardUpdate>
      </div>
      <div class="mt-3 mb-3 text-center">
        <button
          @click="update"
          type="button"
          class="btn btn-success button-default">Update
        </button>
      </div>
      <div class="mt-3 mb-3 text-center">
        <button
          @click="close"
          type="button"
          class="btn btn-danger button-default">Close
        </button>
      </div>
    </div>
  </ModalBase>
</template>

<script>

import GameCardUpdate from '../GameCardUpdate';
import ModalBase from '../../modals/ModalBase';

export default {
  data() {
    return {
    };
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  components: {
    GameCardUpdate,
    ModalBase,
  },
  methods: {
    show() {
      this.$refs.GameCardUpdateModal.show();
    },
    hide() {
      this.$refs.GameCardUpdateModal.hide();
    },
    close() {
      this.hide();
    },
    update() {
      this.$emit('cardUpdate', this.card.id);
      this.hide();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  padding-right: 0 !important;
  .modal-dialog {
    max-width: 300px;
    @media screen and (max-width: 767px) {
      max-width: 80%;
      margin: auto;
    }
    .modal-body {
      padding: 0;
    }
  }
}
</style>
