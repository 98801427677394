export const GameCardTypes = {
  unknown: 0,
  water: 1,
  fire: 2,
  frost: 3,
};

export const GetGameCardName = (cardType) => {
  if (GameCardTypes.water === cardType) {
    return 'Water';
  } 
  if (GameCardTypes.fire === cardType) {
    return 'Fire';
  } 
  if (GameCardTypes.frost === cardType) {
    return 'Frost';
  }
  return null;
}