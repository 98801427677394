<template>
  <div>
    <nav id="navbar-block" class="navbar navbar-expand-md navbar-dark background-color-brand box-shadow-default">
      <span @click="titleEvent" class="navbar-brand pointer">
        <img class="logo" src="@/assets/icons/feather-logo-white.svg">
        <span class="title">Rain Card</span>
      </span>
      <button
        class="navbar-toggler"
        type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse" id="navbar">
        <ul class="navbar-nav">
          <li v-if="isAuthorized" class="nav-item">
            <router-link to="/game-area" class="nav-link">{{ content.gameArea }}</router-link>
          </li>
        </ul>
        <ul class="navbar-nav nav-right">
          <li v-if="!isAuthorized" class="nav-item">
            <router-link to="/login" class="nav-link">{{ content.login }}</router-link>
          </li>
          <li v-if="!isAuthorized" class="nav-item">
            <router-link to="/registration" class="nav-link">{{ content.registration }}</router-link>
          </li>
          <li v-if="isAuthorized" class="nav-item">
            <a @click="profile" class="nav-link">{{ content.profile }}</a>
          </li>
          <li v-if="isAuthorized" class="nav-item">
            <a @click="logout" class="nav-link">{{ content.logout }}</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</template>

<script>

export default {
  data () {
    return {
      content: {
        gameArea: 'Game Area',
        login: 'Login',
        registration: 'Registration',
        products: 'Products',
        about: 'About',
        profile: 'Profile',
        logout: 'Logout',
        admin: 'Admin',
      }
    }
  },
  computed: {
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
  },
  methods: {
    profile() {
      this.$router.push('/profile');
    },
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/');
    },
    titleEvent() {
      this.$router.push('/');
    },
  },
}
</script>

<style lang="scss" scoped>
.navbar {
  width: 100%;
  position: fixed !important;
  z-index: 1000;
  padding: 8px 16px 8px 16px;
}
.navbar-collapse {
  margin-left: 10px;
}
.nav-item {
  cursor: pointer;
}
.dropdown-item {
  cursor: pointer;
}
.nav-right {
  margin-left: auto;
}
.nav-link {
  font-weight: 600;
  color: #FFFFFF !important;
  &:hover {
    color: #FFFFFFD6 !important;
  }
}
.logo {
  width: 35px;
}
.title {
  margin-left: 6px;
}
</style>
