<template>
  <div>
    <div class="text-center bolder">
      {{ content.title }}
    </div>
    <div class="col-md-6 offset-md-3">
      <div class="block-width-fixed">
        <div v-if="!isRegistered">
          <InputBox
            :modelValue="email"
            @update:modelValue="email = $event"
            title="Email"
            type="text"
            :isError="emailError"
            :messageError="content.emailError">
          </InputBox>
          <InputBox
            :modelValue="name"
            @update:modelValue="name = $event"
            :title="content.name"
            type="text"
            :isError="nameError"
            :messageError="content.nameError">
          </InputBox>
          <PasswordCreate @result="passwordResult"></PasswordCreate>
          <div class="pt-3 text-center">
            <button
              @click="register"
              :disabled="registerDisabled" type="button"
              class="btn button-default btn-success">{{ content.register }}
            </button>
          </div>
        </div>
        <div v-else class="pt-3">
          <div class="text-center">
            <span>{{ content.registered(name, email) }}</span>
          </div>
          <div class="pt-3">
            <UserActivation :emailProp="email"></UserActivation>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { checkEmail } from '../../services/validateService';
import PasswordCreate from '../../components/PasswordCreate';
import UserActivation from '../../components/UserActivation';
import { userController } from '../../services/apiService';
import InputBox from '../../components/InputBox';

export default {
  data () {
    return {
      email: '',
      name: '',
      password: '',
      isRegistered: false,

      content: {
        title: 'Registration',
        enterEmail: 'Enter email',
        name: 'Name',
        enterName: 'Enter name',
        register: 'Register',
        emailError: 'Incorrect email',
        nameError: 'Name must be specified',
        registered: (name, email) => `User "${name}" is registered. A confirmation code has been sent to your mail "${email}"`,
      },
    }
  },
  components: {
    InputBox,
    PasswordCreate,
    UserActivation,
  },
  computed: {
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
    nameError() {
      if (this.name.length === 0) {
        return true;
      }
      return false;
    },
    registerDisabled() {
      if (!this.emailError && !this.nameError && this.password.length > 0) {
        return false;
      }
      return true;
    },
  },
  methods: {
    passwordResult(password) {
      this.password = password;
    },
    register() {
      userController.createUserHttpPost(this.email, this.name, this.password)
        .then(() => {
          this.isRegistered = true;
        });
    },
  },
}
</script>

<style lang="scss">
</style>
