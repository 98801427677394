<template>
  <div>
    <div class="text-center bolder">
      {{ content.title }}
    </div>
    <div class="text-center pt-3">
      {{ content.description }}
    </div>
    <div v-if="!isActivationCodeSent" class="col-md-6 offset-md-3 pt-3">
      <InputBox
        :modelValue="email"
        @update:modelValue="email = $event"
        :content="email"
        title="Email"
        type="text"
        :isError="emailError"
        :messageError="content.emailError">
      </InputBox>
      <div class="pt-3 text-center">
        <button
          @click="sendActivationCode"
          type="button"
          :disabled="emailError"
          class="btn button-default btn-success">{{ content.sendActivationCode }}
        </button>
      </div>
      <div class="pt-3 text-center">
        <button
          @click="isActivationCodeSent = true"
          type="button"
          class="btn button-default btn-success">{{ content.alreadyHaveCode }}
        </button>
      </div>
    </div>
    <div v-else class="col-md-6 offset-md-3 pt-3">
      <UserActivation
        :emailProp="email"
        :hideTitle="true"
        @activationSuccess="isTryAgainHidden = true">
      </UserActivation>
      <div v-if="!isTryAgainHidden" class="pt-3 text-center">
        <button
          @click="isActivationCodeSent = false"
          type="button"
          class="btn button-default btn-success">{{ content.tryAgain }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import { checkEmail } from '../../services/validateService';
import { userController } from '../../services/apiService';
import UserActivation from '../../components/UserActivation';
import InputBox from '../../components/InputBox';

export default {
  data () {
    return {
      email: '',
      isActivationCodeSent: false,
      isTryAgainHidden: false,

      content: {
        title: 'Account activation',
        description: 'An activation code will be sent to your email address, which will need to be specified in the next step.',
        emailError: 'Incorrect email',
        sendActivationCode: 'Send activation сode',
        tryAgain: 'Try again',
        alreadyHaveCode: 'I have the code',
      },
    }
  },
  components: {
    UserActivation,
    InputBox,
  },
  created() {
    this.init();
  },
  computed: {
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
  },
  methods: {
    init() {
      this.email = this.$route.query.email;
    },
    sendActivationCode() {
      userController.sendNewRegistrationCodeHttpPost(this.email)
        .then(() => {
          this.isActivationCodeSent = true;
        });
    },
  },
}
</script>

<style scoped>
</style>
