<template>
  <div
    v-if="card"
    :style="{ 'background-color': getCardColor() }"
    class="card-block block-default noselect">
    <div v-if="isActiveTagVisible" class="card-block-item">
      <div class="active-tag"></div>
    </div>
    <div class="card-block-title text-center">
      <span class="bolder"><img class="card-title-icon" :src="getCardIcon()"></span>
    </div>
    <div
      :class="{ 'visibility-hidden': !isActive }"
      class="bolder mt-2 text-left">
      <div class="card-block-item text-center">
        <span>{{ card.level }}</span>
      </div>
      <div class="card-block-item mt-1">
        <span><img class="card-icon" src="@/assets/icons/health-icon.svg"></span>
        <span>&nbsp;&nbsp;&nbsp;{{ card.healthReal }}/{{ card.health }}</span>
      </div>
      <div class="card-block-item">
        <span><img class="card-icon" src="@/assets/icons/armor-icon.svg"></span>
        <span>&nbsp;&nbsp;&nbsp;{{ card.armor }}</span>
      </div>
      <div class="card-block-item">
        <span><img class="card-icon" src="@/assets/icons/damage-icon.svg"></span>
        <span>&nbsp;&nbsp;&nbsp;{{ card.damage }}</span>
      </div>
      <div class="card-block-item">
        <span><img class="card-icon" src="@/assets/icons/restoration-icon.svg"></span>
        <span>&nbsp;&nbsp;&nbsp;{{ card.restoration }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { GameCardTypes } from '../../consts/gameCardTypes';
import { GameUserStatus } from '../../consts/gameUserStatus';

export default {
  data () {
    return {
    }
  },
  components: {
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    card: {
      type: Object,
      default: null,
    },
    isUserContext: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isActive() {
      return this.card.type !== GameCardTypes.unknown;
    },
    isActiveTagVisible() {
      if (!this.user) {
        return false;
      }
      if (this.user.id === this.card.gameUserId) {
        const gameCardsUser = this.user.gameCards;
        if (this.user.status === GameUserStatus.moveActive) {
          if (this.card.isActive && !this.card.actionCompleted || !this.card.isActive && !gameCardsUser.some(x => x.showCompleted)) {
            return true;
          }
        }
        if (this.user.status === GameUserStatus.roundUpdateActive) {
          if (!gameCardsUser.some(x => x.updateCompleted)) {
            return true;
          }
        }
      }
      return false;
    },
  },
  methods: {
    getCardIcon() {
      let icon = null;
      if (this.card.type === GameCardTypes.unknown) {
        icon = 'question-circle.svg';
      } else if (this.card.type === GameCardTypes.water) {
        icon = 'water-icon.svg';
      } else if (this.card.type === GameCardTypes.fire) {
        icon = 'fire-icon.svg';
      } else if (this.card.type === GameCardTypes.frost) {
        icon = 'frost-icon.svg';
      }
      return require(`@/assets/icons/${icon}`);
    },
    getCardColor() {
      let color = null;
      if (!this.card.isActive) {
        color = '#a0a0a0';
      } else if (this.card.healthReal === 0) {
        color = '#a0a0a0';
      } else if (this.card.type === GameCardTypes.water) {
        color = '#5a93ce';
      } else if (this.card.type === GameCardTypes.fire) {
        color = '#ceaa5a';
      } else if (this.card.type === GameCardTypes.frost) {
        color = '#5ac9ce';
      }
      return color;
    },
  },
}
</script>

<style lang="scss" scoped>
.card-block {
  padding: 20px;
  border-radius: 10px;
  height: 200px;
  width: 120px;
  cursor: pointer;
  .card-block-title {
    .card-title-icon {
      width: 30px;
      height: 30px;
    }
  }
  .card-block-item {
    .card-icon {
      width: 20px;
    }
  }
  .active-tag {
    background-color: green;
    border-radius: 50px;
    height: 6px;
    width: 6px;
    position: absolute;
  }
}
</style>
