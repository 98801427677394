<template>
  <div
    v-if="card"
    :style="{ 'background-color': getCardColor() }"
    class="card-block block-default noselect">
    <div class="card-block-title text-center">
      <span class="bolder"><img class="card-title-icon" :src="getCardIcon()"></span>
    </div>
    <div
      :class="{ 'visibility-hidden': !isActive }"
      class="bolder text-left mt-3">
      <div
        class="card-block-item">
        <div class="card-block-item-part">
          <span>+{{ card.healthUpdate }}</span>
        </div>
        <div class="card-block-item-part ml-2">
          <span><img class="card-icon" src="@/assets/icons/health-icon.svg"></span>
          <span class="ml-2">{{ card.healthReal }}/{{ card.health }}</span>
        </div>
      </div>
      <div
        class="card-block-item">
        <div class="card-block-item-part">
          <span>+{{ card.armorUpdate }}</span>
        </div>
        <div class="card-block-item-part ml-2">
        <span><img class="card-icon" src="@/assets/icons/armor-icon.svg"></span>
        <span class="ml-2">{{ card.armor }}</span>
        </div>
      </div>
      <div
        class="card-block-item">
        <div class="card-block-item-part">
          <span>+{{ card.damageUpdate }}</span>
        </div>
        <div class="card-block-item-part ml-2">
        <span><img class="card-icon" src="@/assets/icons/damage-icon.svg"></span>
        <span class="ml-2">{{ card.damage }}</span>
        </div>
      </div>
      <div
        class="card-block-item">
        <div class="card-block-item-part">
          <span>+{{ card.restorationUpdate }}</span>
        </div>
        <div class="card-block-item-part ml-2">
        <span><img class="card-icon" src="@/assets/icons/restoration-icon.svg"></span>
        <span class="ml-2">{{ card.restoration }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { GameCardTypes } from '../../consts/gameCardTypes';

export default {
  data () {
    return {
    }
  },
  components: {
  },
  props: {
    card: {
      type: Object,
      default: null,
    },
  },
  computed: {
    isActive() {
      return this.card.type !== GameCardTypes.unknown;
    },
  },
  methods: {
    getCardIcon() {
      let icon = null;
      if (this.card.type === GameCardTypes.unknown) {
        icon = 'question-circle.svg';
      } else if (this.card.type === GameCardTypes.water) {
        icon = 'water-icon.svg';
      } else if (this.card.type === GameCardTypes.fire) {
        icon = 'fire-icon.svg';
      } else if (this.card.type === GameCardTypes.frost) {
        icon = 'frost-icon.svg';
      }
      return require(`@/assets/icons/${icon}`);
    },
    getCardColor() {
      let color = null;
      if (!this.card.isActive) {
        color = '#a0a0a0';
      } else if (this.card.type === GameCardTypes.water) {
        color = '#5a93ce';
      } else if (this.card.type === GameCardTypes.fire) {
        color = '#ceaa5a';
      } else if (this.card.type === GameCardTypes.frost) {
        color = '#5ac9ce';
      }
      return color;
    },
  },
}
</script>

<style lang="scss" scoped>
.card-block {
  padding: 20px;
  border-radius: 10px;
  height: 260px;
  width: 200px;
  display: inline-block;
  .card-block-title {
    .card-title-icon {
      width: 30px;
      height: 30px;
    }
  }
  .card-block-item {
    border-radius: 6px;
    padding: 10px;
    .card-icon {
      width: 20px;
    }
    .card-block-item-part {
      display: inline-block;
    }
  }
}
</style>
