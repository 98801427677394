<template>
  <div class="game-card-block">
    <GameCard
      v-for="(item, index) in cards" :key="index"
      @click="cardEvent(item)"
      :user="user"
      :card="item"
      :isUserContext="isUserContext"
      class="game-card-item">
    </GameCard>
  </div>
</template>

<script>

import GameCard from './GameCard';

export default {
  data () {
    return {
    }
  },
  components: {
    GameCard,
  },
  props: {
    user: {
      type: Object,
      default: null,
    },
    cards: {
      type: Array,
      default: () => [],
    },
    isUserContext: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cardEvent(item) {
      this.$emit('cardEvent', item);
    },
  },
}
</script>

<style lang="scss" scoped>
.game-card-block {
  width: 100%;
  display: inline-block;
  text-align: center;
  .game-card-item {
    display: inline-block;
    margin: 5px;
  }
}
</style>
