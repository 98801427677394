<template>
  <div 
    id="header-block-id"
    class="header-block background-color-brand box-shadow-default noselect">
    <div class="button-block">
      <div @click="menuClick" class="header-button">
        <img class="header-button-icon" src="@/assets/icons/header-icon.svg">
      </div>
    </div>
    <div class="title-block">
      <span @click="titleClick" class="title-item pointer">
        <img class="logo" src="@/assets/icons/feather-logo-white.svg">
        <span class="title">Rain Card</span>
      </span>
    </div>
    <div 
      id="side-menu-block-id"
      class="side-menu-block"
      :style="{
        height: sideMenuHeight + 'px',
        left: sideMenuLeft + 'px',
      }">
      <div v-for="(item, index) in items" :key="index">
        <div
          v-if="!item.hidden"
          class="side-menu-item"
          @click="menuItemAction(item)">
          <span>{{ item.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { checkPointInRectangle, checkMobileBrowser } from '../services/validateService';
import { MenuItemTypes } from '../consts/menuItemTypes';

export default {
  data () {
    return {
      headerBlockHeight: 0,
      isMobileBrowser: false,
      isMenuOpen: false,
      isMoveActive: false,
      pressEvent: null,
      moveEvent: null,
      sideMenuHeight: 0,
      sideMenuLeft: -300,
      sideMenuOffsetLeft: 0,
      sideMenuOpenRect: {
        x: 0,
        y: 0,
        width: 40,
        height: 0,
      },
    }
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.init();
  },
  mounted() {
    this.countMenu();
    window.addEventListener("resize", this.resizeAction);

    if (this.isMobileBrowser) {
      window.addEventListener("touchstart", this.pressAction);
      window.addEventListener("touchmove", this.moveAction);
      window.addEventListener("touchend", this.upAction);
    } else {
      window.addEventListener("mousedown", this.pressAction);
      window.addEventListener("mousemove", this.moveAction);
      window.addEventListener("mouseup", this.upAction);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.resizeAction);

    if (this.mobileBrowser) {
      window.removeEventListener("touchstart", this.pressAction);
      window.removeEventListener("touchmove", this.moveAction);
      window.removeEventListener("touchend", this.upAction);
    } else {
      window.removeEventListener("mousedown", this.pressAction);
      window.removeEventListener("mousemove", this.moveAction);
      window.removeEventListener("mouseup", this.upAction);
    }
  },
  watch: {
    isMenuOpen() {
      this.countMenuLeft();
    },
    isMoveActive() {
      let sideMenu = document.getElementById('side-menu-block-id');
      sideMenu.style.transition = this.isMoveActive ? 'none' : 'all 500ms ease';
    },
  },
  methods: {
    init() {
      this.isMobileBrowser = checkMobileBrowser();
    },

    // events
    resizeAction() {
      this.countMenu();
    },
    pressAction(event) {
      this.pressEvent = event;
      const point = this.getEventPoint(event);
      if (this.isMenuOpen && this.isClickOnElement('side-menu-block-id', event) || !this.isMenuOpen && checkPointInRectangle(point, this.sideMenuOpenRect)) {
        this.isMoveActive = true;
        this.moveEvent = event;
        const sideMenu = document.getElementById('side-menu-block-id');
        this.sideMenuOffsetLeft = point.x - sideMenu.offsetLeft;
      } else if (this.isMenuOpen && !this.isClickOnElement('header-block-id', event)) {
        this.closeMenu();
      }
    },
    moveAction(event) {
      this.moveEvent = event;
      const point = this.getEventPoint(event);
      if (!this.isMoveActive) return;
      let sideMenu = document.getElementById('side-menu-block-id');
      const left = point.x - this.sideMenuOffsetLeft;
      if (left >= -sideMenu.offsetWidth && left <= 0) {
        this.sideMenuLeft = left;
      }
    },
    upAction() {
      if (!this.isMoveActive) return;
      this.isMoveActive = false;
      const sideMenu = document.getElementById('side-menu-block-id');
      const rect = sideMenu.getBoundingClientRect();
      const percentHidden = Math.round(100 * (rect.width - rect.right) / rect.width);
      const shouldBeHidden = percentHidden > 50;
      if (shouldBeHidden) {
        if (this.isMenuOpen) {
          this.closeMenu();
        } else {
          this.sideMenuLeft = -sideMenu.offsetWidth;
        }
      } else {
        this.isMenuOpen = true;
        this.sideMenuLeft = 0;
      }
    },

    // count
    countMenu() {
      this.countMenuHeight();
      this.countMenuLeft();
    },
    countMenuHeight() {
      const headerBlock = document.getElementById('header-block-id');
      this.sideMenuHeight = window.innerHeight - headerBlock.offsetHeight;
      this.sideMenuOpenRect.y = headerBlock.offsetHeight;
      this.sideMenuOpenRect.height = this.sideMenuHeight;
    },
    countMenuLeft() {
      let sideMenu = document.getElementById('side-menu-block-id');
      this.sideMenuLeft = this.isMenuOpen ? 0 : -sideMenu.offsetWidth;
    },

    //menu click
    menuClick() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    titleClick() {
      if (this.isMenuOpen) this.closeMenu();
      this.$router.push('/');
    },
    menuItemAction(item) {
      if (!this.isClickOnePoint()) return;
      this.closeMenu();
      if (item.type === MenuItemTypes.action) {
        item.action();
      } else if (item.type === MenuItemTypes.route) {
        this.$router.push(item.route);
      }
    },

    // common
    closeMenu() {
      this.isMenuOpen = false;
    },
    isClickOnePoint() {
      const pressPoint = this.getEventPoint(this.pressEvent);
      const movePoint = this.getEventPoint(this.moveEvent);
      return pressPoint.x === movePoint.x && pressPoint.y === movePoint.y;
    },
    isClickOnElement(id, event) {
      const element = document.getElementById(id);
      return element.contains(event.target);
    },
    getEventPoint(event) {
      if (this.isMobileBrowser) {
        const touch = event.touches[0];
        return { x: touch.clientX, y: touch.clientY };
      } else {
        return { x: event.clientX, y: event.clientY };
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.header-block {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 60px;
  display: inline-block;
  text-align: center;
  .button-block {
    position: absolute;
    padding-top: 11px;
    margin-left: 16px;
    .header-button {
      transition: all 0.3s ease;
      cursor: pointer;
      padding: 4px 12px;
      font-size: 1.25rem;
      line-height: 1;
      background-color: transparent;
      border: 1px solid hsla(0,0%,100%,.1);
      border-radius: .25rem;
      overflow: visible;
      text-transform: none;
      margin: 0;
      .header-button-icon {
        width: 25px;
      }
    }
    .header-button:hover {
      border-color: #ffffff4d;
    }
  }
  .title-block {
    display: inline-block;
    padding-top: 7px;
    .title-item {
      display: inline-block;
      padding-top: .3125rem;
      padding-bottom: .3125rem;
      font-size: 1.25rem;
      line-height: inherit;
      white-space: nowrap;
      .logo {
        width: 35px;
      }
      .title {
        margin-left: 6px;
        color: #fff;
      }
      @media screen and (max-width: 300px) {
        .title {
          display: none;
        }
      }
    }
  }
  .side-menu-block {
    position: absolute;
    top: 60px;
    background-color: #050717;
    transition: all 500ms ease;
    text-align: left;
    opacity: 0.95;
    @media screen and (max-width: 300px) {
      width: 100%;
    }
    @media screen and (min-width: 301px) and (max-width: 340px) {
      width: 250px;
    }
    @media screen and (min-width: 341px) and (max-width: 400px) {
      width: 280px;
    }
    @media screen and (min-width: 401px) and (max-width: 767px) {
      width: 320px;
    }
    @media screen and (min-width: 768px) {
      width: 350px;
    }
    .side-menu-item {
      position: relative;
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 30px;
      color: #fff;
      cursor: pointer;
      transition: all 500ms ease;
      font-weight: 600;
    }
    .side-menu-item:hover {
      background:rgba(255,255,255, 0.2);
    }
  }
}
</style>
