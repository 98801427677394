<template>
  <ModalBase ref="PickerBoxModal">
    <div class="text-center">
      <div>
        <PickerBox
          @select="select"
          @hide="hide"
          :options="options">
        </PickerBox>
      </div>
      <div class="mt-3 mb-3 text-center">
        <button
          @click="close"
          type="button"
          class="btn btn-success">Close
        </button>
      </div>
    </div>
  </ModalBase>
</template>

<script>

import PickerBox from '../PickerBox';
import ModalBase from '../../modals/ModalBase';

export default {
  data() {
    return {
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      required: true,
    },
  },
  components: {
    PickerBox,
    ModalBase,
  },
  methods: {
    show() {
      this.$refs.PickerBoxModal.show();
    },
    hide() {
      this.$refs.PickerBoxModal.hide();
    },
    close() {
      this.hide();
    },
    select(value) {
      this.$emit('select', value);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
