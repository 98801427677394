<template>
  <ModalBase ref="ApproveModal" :delay="10">
    <div class="approve-modal">
      <div class="mt-2 mb-2 bolder text-center">
        <span>{{ approveModal.content }}</span>
      </div>
      <div class="col-sm-12">
        <div class="row m-0 text-center">
          <div class="col-sm-6 mt-3">
            <button @click="cancel" type="button" class="btn btn-danger button-default">{{ content.cancel }}</button>
          </div>
          <div class="col-sm-6 mt-3">
            <button @click="approve" type="button" class="btn btn-success button-default">{{ content.approve }}</button>
          </div>
        </div>
      </div>
    </div>
  </ModalBase>
</template>

<script>

import ModalBase from '../modals/ModalBase';

export default {
  data() {
    return {
      content: {
        cancel: 'Cancel',
        approve: 'Approve',
      },
    };
  },
  components: {
    ModalBase,
  },
  computed: {
    approveModal() {
      return this.$store.state.approveModal;
    },
  },
  watch: {
    approveModal() {
      setTimeout(this.approveModalEvent, 1);
    },
  },
  methods: {
    approveModalEvent() {
      if (this.approveModal) {
        this.show();
      }
    },
    show() {
      this.$refs.ApproveModal.show();
    },
    hide() {
      this.$refs.ApproveModal.hide();
      this.approveModalClear();
    },
    cancel() {
      if (this.approveModal.cancelAction) {
        this.approveModal.cancelAction();
      }
      this.hide();
    },
    approve() {
      if (this.approveModal.approveAction) {
        this.approveModal.approveAction();
      }
      this.hide();
    },
    approveModalClear() {
      this.$store.dispatch('setApproveModal', null);
    },
  },
};
</script>

<style lang="scss" scoped>
.approve-modal {
  padding-top: 30px;
  padding-bottom: 30px;
}
</style>
