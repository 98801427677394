const signalR = require('@microsoft/signalr');
import Store from '../store';

export const gameHub = {
  connectionCreate: function() {
    const token = Store.getters.getTokenAccess;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.VUE_APP_API_URL}gamehub`, { accessTokenFactory: () => token })
      .withAutomaticReconnect({
        nextRetryDelayInMilliseconds: (retryContext) => {
          if (retryContext.elapsedMilliseconds < 60000) {
            return 5000;
          } else {
            return 10000;
          }
        }
      })
      .build();
    Store.dispatch('setConnection', connection);
  },
  invoke: function(method, obj = null) {
    const connection = Store.getters.getConnection;
    if (obj) {
      const json = JSON.stringify(obj);
      return connection.invoke(method, json);
    } else {
      return connection.invoke(method);
    }
  },
}