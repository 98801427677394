<template>
  <div class="timer-box">
    <span>{{ value }}</span>
  </div>
</template>

<script>

export default {
  data() {
    return {
      timer: null,
      value: 0,
    };
  },
  computed: {
    gameTimerDelay() {
      return this.$store.getters.getGameTimerDelay;
    },
  },
  methods: {
    init() {
      this.clear();
      if (this.gameTimerDelay > 0) {
        this.value = this.gameTimerDelay;
        this.start();
      }
    },
    start() {
      this.timer = setInterval(() => { this.event() }, 1000);
    },
    event() {
      if (this.value === 0 || !this.gameTimerDelay) {
        this.clear();
      }
      this.value = this.gameTimerDelay - 1;
      this.$store.dispatch('setGameTimerDelay', this.value);
    },
    clear() {
      clearInterval(this.timer);
      this.timer = null;
      this.value = 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.timer-box {
  position: relative;
}
</style>
