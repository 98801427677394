<template>
  <div>
    <div class="col-md-8 offset-md-2">
      <div class="user-info">
        <div class="col-sm-12">
          <div class="row m-0">
            <div class="col-sm-6">
              <div class="mt-3">Status: {{ gameHubStatus }}</div>
              <div class="mt-3">Online: {{ gameHubState.usersConnected }}</div>
              <div class="mt-3">Searching game: {{ gameHubState.usersGameSearch }}</div>
            </div>
            <div class="col-sm-6">
              <div class="mt-3">
                <span>Name: </span>
                <span v-if="user">{{ user.name }}</span>
              </div>
              <div class="mt-3">
                <span>Wins: </span>
                <span v-if="user">{{ user.wins }}</span>
              </div>
              <div class="mt-3">
                <span>Defeats: </span>
                <span v-if="user">{{ user.defeats }}</span>
              </div>
              <div class="mt-3">
                <span>Deck:</span>
                <span v-if="!decksExists">
                  <span class="ml-2 deck-create">
                    <img v-show="!isDeckMenuActive" @click="openDeckMenu" class="deck-create-icon" src="@/assets/icons/plus-square-icon.svg">
                    <img v-show="isDeckMenuActive" @click="closeDeckMenu" class="deck-create-icon" src="@/assets/icons/close-gray-icon.svg">
                  </span>
                </span>
                <span v-else-if="deckActive">
                  <span class="ml-2">{{ deckActive.name }}</span>
                  <span class="ml-2">
                    <img v-show="!isDeckMenuActive" @click="openDeckMenu" class="deck-edit-icon" src="@/assets/icons/pen-square-icon.svg">
                    <img v-show="isDeckMenuActive" @click="closeDeckMenu" class="deck-edit-icon" src="@/assets/icons/close-gray-icon.svg">
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-3 text-center">
        <button
          v-if="gameHubStatus === gameHubStatusList.wait"
          @click="searchGameStart"
          :disabled="isSearchGameStartDisabled"
          type="button" class="btn btn-success button-default">Search game</button>
        <button
          v-else-if="gameHubStatus === gameHubStatusList.search"
          @click="searchGameStop" 
          type="button" class="btn btn-danger button-default">Stop searching</button>
        <button
          v-else-if="gameHubStatus === gameHubStatusList.found"
          @click="leaveGame" 
          type="button" class="btn btn-danger button-default">Leave game</button>
      </div>
      <div v-if="isDeckMenuActive" class="mt-3">
        <DeckMenu
          ref="DeckMenu"
          @createDeck="createDeck"
          @updateDeck="updateDeck"
          @removeDeck="removeDeck">
        </DeckMenu>
      </div>
      <div v-if="gameHubStatus === gameHubStatusList.found" class="mt-5">
        <Game
          ref="Game"
          :item="game">
        </Game>
      </div>
    </div>
  </div>
</template>

<script>

import { GameHubStatus } from '../../consts/gameHubStatus';
import { GameStatus } from '../../consts/gameStatus';
import DeckMenu from '../../components/game/DeckMenu';
import Game from '../../components/game/Game';
import { userController } from '../../services/apiService';
import { gameHub } from '../../services/hubService';

export default {
  data () {
    return {
      gameHubStatusList: GameHubStatus,
      gameHubStatus: GameHubStatus.disconnected,
      gameSearchActive: false,
      gameLast: null,
      gameHubState: {
        usersConnected: 0,
        usersGameSearch: 0,
      },
      user: null,
      game: null,
      isDeckMenuActive: false,
    }
  },
  components: {
    DeckMenu,
    Game,
  },
  created() {
    this.init();
  },
  computed: {
    isAuthorized() {
      return this.$store.getters.isAuthorized;
    },
    getUser() {
      return this.$store.getters.getUser;
    },
    gameHubConnection() {
      return this.$store.getters.getConnection;
    },
    deckActive() {
      if (this.user && this.user.decks) {
        return this.user.decks.find(x => x.isActive);
      }
      return null;
    },
    decksExists() {
      return this.user && this.user.decks && this.user.decks.length > 0;
    },
    isSearchGameStartDisabled() {
      return !this.decksExists || this.isDeckMenuActive;
    },
  },
  methods: {
    init() {
      this.initGameHubEvents();
      this.initConnection();
    },
    initConnection() {
      if (this.gameHubConnection.connectionState !== 'Connected') {
        this.startConnection();
      } else {
        this.getGameHubState();
      }
    },
    startConnection() {
      this.gameHubConnection.start()
        .then(() => {
          this.onConnectedAuthorized();
          this.gameHubStatus = GameHubStatus.wait;
        })
        .catch(() => setTimeout(this.initConnection, 10000));
    },
    initGameHubEvents() {
      // 1. Connect Actions
      this.gameHubConnection.onreconnecting(() => {
        this.gameHubStatus = GameHubStatus.reconnecting;
        this.gameHubClear();
      });
      this.gameHubConnection.onreconnected(() => {
        this.gameHubStatus = GameHubStatus.wait;
        const user = {
          id: this.getUser.id,
          name: this.getUser.name,
          email: this.getUser.email,
        };
        const reconnectedAsyncModel = {
          userAuthorized: user
        };
        gameHub.invoke('ReconnectedAsync', reconnectedAsyncModel);
      });
      this.gameHubConnection.on("ConnectedEvent", data => {
        const model = this.parseJson(data);
        this.gameHubState = model.gameHubState;
      });
      this.gameHubConnection.on("ConnectedUserEvent", (data) => {
        const model = this.parseJson(data);
        this.setCards(model.cards);
        this.gameHubState = model.gameHubState;
        this.updateUser(model.gameUserProfile);
        this.game = model.game;
        this.setGameTimerDelay(model.gameTimerDelay);
        this.checkGame();
      });
      this.gameHubConnection.on("ReconnectedEvent", (data) => {
        const model = this.parseJson(data);
        this.setCards(model.cards);
        this.gameHubState = model.gameHubState;
        this.updateUser(model.gameUserProfile);
        this.game = model.game;
        this.setGameTimerDelay(model.gameTimerDelay);
        this.checkGame();
      });
      this.gameHubConnection.on("DisconnectedEvent", data => {
        const model = this.parseJson(data);
        this.gameHubState = model.gameHubState;
      });

      // 2. Game Lobby Actions
      this.gameHubConnection.on("GetGameHubStateEvent", (data) => {
        const model = this.parseJson(data);
        this.gameHubState = model.gameHubState;
        this.game = model.game;
        this.checkGame();
      });
      this.gameHubConnection.on("SearchGameStartEvent", data => {
        const model = this.parseJson(data);
        this.gameHubStatus = GameHubStatus.search;
        this.gameHubState.usersGameSearch = model.usersGameSearch;
      });
      this.gameHubConnection.on("SearchGameStopEvent", data => {
        const model = this.parseJson(data);
        this.gameHubStatus = GameHubStatus.wait;
        this.gameHubState.usersGameSearch = model.usersGameSearch;
      });
      this.gameHubConnection.on("SearchGameEvent", data => {
        const model = this.parseJson(data);
        this.gameHubState.usersGameSearch = model.usersGameSearch;
      });
      this.gameHubConnection.on("SearchGameFoundEvent", (data) => {
        const model = this.parseJson(data);
        console.log('SearchGameFoundEvent', model);
        this.gameHubState = model.gameHubState;
        this.game = model.game;
        this.setGameTimerDelay(model.gameTimerDelay);
        this.checkGame();
      });
      this.gameHubConnection.on("CreateDeckEvent", (data) => {
        const model = this.parseJson(data);
        this.createUserDeck(model);
      });
      this.gameHubConnection.on("UpdateDeckEvent", (data) => {
        const model = this.parseJson(data);
        this.updateUserDeck(model);
      });
      this.gameHubConnection.on("RemoveDeckEvent", (data) => {
        const model = this.parseJson(data);
        this.removeUserDeck(model);
      });
      
      // 3. Game actions
      this.gameHubConnection.on("GameLeaveEvent", (data) => {
        const model = this.parseJson(data);
        if (model.isWinner) {
          this.user.wins++;
        } else {
          this.user.defeats++;
        }
        this.game = model.game;
        this.checkGame();
      });
      this.gameHubConnection.on("CloseGameEvent", (data) => {
        const model = this.parseJson(data);
        if (model.isWinner) {
          this.user.wins++;
        } else {
          this.user.defeats++;
        }
        this.game = model.game;
        this.checkGame();
      });
      this.gameHubConnection.on("GameStartEvent", data => {
        const model = this.parseJson(data);
        this.setGameTimerDelay(model.gameTimerDelay);
        this.$refs.Game.gameStartEvent(model.game);
      });
      this.gameHubConnection.on("GameCardShowEvent", (data) => {
        const model = this.parseJson(data);
        this.$refs.Game.gameCardShowEvent(model.userId, model.card);
      });
      this.gameHubConnection.on("GameUserStatusEvent", (data) => {
        const model = this.parseJson(data);
        this.setGameTimerDelay(model.gameTimerDelay);
        this.$refs.Game.gameUserStatusEvent(model.status);
      });
      this.gameHubConnection.on("GameCardActionEvent", (data) => {
        const model = this.parseJson(data);
        // COMMENT: model.cardAction - no need for web now but need in unity
        this.$refs.Game.gameCardActionEvent(model.cardSource, model.cardTarget);
      });
      this.gameHubConnection.on("GameRoundCardUpdateEvent", data => {
        const model = this.parseJson(data);
        this.$refs.Game.gameRoundCardUpdateEvent(model.card);
      });
      this.gameHubConnection.on("GameRoundCompleteEvent", data => {
        const model = this.parseJson(data);
        this.$refs.Game.gameRoundCompleteEvent(model.game);
      });
      this.gameHubConnection.on("GameRoundNewEvent", (data) => {
        const model = this.parseJson(data);
        this.setGameTimerDelay(model.gameTimerDelay);
        this.$refs.Game.gameRoundNewEvent(model.game);
      });
    },
    onConnectedAuthorized() {
      const user = {
        id: this.getUser.id,
        name: this.getUser.name,
        email: this.getUser.email,
      };
      const onConnectedAuthorizedAsyncModel = {
        userAuthorized: user
      };
      gameHub.invoke('OnConnectedAuthorizedAsync', onConnectedAuthorizedAsyncModel)
      .catch(() => {
        this.$store.dispatch('removeTokenAccess');
        userController.refreshTokenHttpPost()
          .then((data) => {
            const tokenData = {
              tokenAccess: data.tokenAccess,
              tokenRefresh: data.tokenRefresh,
            };
            this.$store.dispatch('login', tokenData);
            this.$store.dispatch('setUser', data);
            gameHub.connectionCreate();
            this.init();
          });
      });
    },
    getGameHubState() {
      gameHub.invoke('GetGameHubStateAsync');
    },
    searchGameStart() {
      gameHub.invoke('SearchGameStartAsync');
    },
    searchGameStop() {
      gameHub.invoke('SearchGameStopAsync');
    },
    createDeck(deck) {
      gameHub.invoke('CreateDeckAsync', deck);
    },
    updateDeck(deck) {
      gameHub.invoke('UpdateDeckAsync', deck);
    },
    removeDeck(model) {
      gameHub.invoke('RemoveDeckAsync', model);
    },
    leaveGame() {
      const leaveGameAsyncModel = {
        gameId: this.game.id
      };
      gameHub.invoke('LeaveGameAsync', leaveGameAsyncModel);
    },
    checkGame() {
      if (!this.game) {
        this.gameHubStatus = GameHubStatus.wait;
      } else if (this.game.status === GameStatus.active) {
        this.gameHubStatus = GameHubStatus.found;
      } else if (this.game.status === GameStatus.completed) {
        this.gameHubStatus = GameHubStatus.wait;
        this.gameLast = this.game;
        this.game = null;
      }
    },
    gameHubClear() {
      this.game = null;
      this.gameHubState = {
        usersConnected: 0,
        usersGameSearch: 0,
      };
    },
    updateUser(user) {
      this.user = user;
      let userStore = this.getUser;
      userStore.id = this.user.id;
      userStore.name = this.user.name;
      userStore.wins = this.user.wins;
      userStore.defeats = this.user.defeats;
      userStore.decks = this.user.decks;
      this.$store.dispatch('setUser', userStore);
    },
    createUserDeck(model) {
      this.updateDecks(model);
    },
    updateUserDeck(model) {
      this.updateDecks(model);
    },
    removeUserDeck(model) {
      this.updateDecks(model);
    },
    updateDecks(model) {
      const decks = model.decks;
      if (!decks) return;
      this.user.decks = decks;
      let userStore = this.getUser;
      userStore.decks = this.user.decks;
      this.$refs.DeckMenu.closeDeckMenu();
      this.$store.dispatch('setUser', userStore);
    },
    setCards(cards) {
      this.$store.dispatch('setCards', cards);
    },
    openDeckMenu() {
      if (this.gameHubStatus !== this.gameHubStatusList.wait) return;
      this.isDeckMenuActive = true;
    },
    closeDeckMenu() {
      if (this.gameHubStatus !== this.gameHubStatusList.wait) return;
      this.isDeckMenuActive = false;
    },

    // common methods
    parseJson(json) {
      return JSON.parse(json);
    },
    setGameTimerDelay(gameTimerDelay) {
      this.$store.dispatch('setGameTimerDelay', Math.round(gameTimerDelay / 1000));
    },
  },
}
</script>

<style lang="scss" scoped>
.user-info {
  position: relative;
  .deck-edit-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}
.deck-create {
  .deck-create-icon {
    cursor: pointer;
    width: 25px;
    height: 25px;
  }
}
</style>
