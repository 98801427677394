<template>
  <div>
    <div class="col-md-8 offset-md-2">
      <div class="text-center bolder font-size-20 mt-3">
        <span>Rain Card</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
    }
  },
  methods: {
  },
}
</script>

<style scoped>
</style>
