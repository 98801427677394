<template>
  <div>
    <div class="text-center bolder">
      {{ content.title }}
    </div>
    <div v-if="user" class="col-md-6 offset-md-3 mt-3">
      <div class="text-center bolder">
        {{ content.personalInfo }}
      </div>
      <div>
        <table class="table table-bordered table-symmetrical mt-3">
          <tbody>
            <tr>
              <td>
                Email
              </td>
              <td>
                {{ user.email }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content.name }}
              </td>
              <td>
                {{ user.name }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content.wins }}
              </td>
              <td>
                {{ user.wins }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content.defeats }}
              </td>
              <td>
                {{ user.defeats }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content.registrationDate }}
              </td>
              <td>
                {{ dateUTCToLocalFunc(user.created) }}
              </td>
            </tr>
            <tr>
              <td>
                {{ content.loginDate }}
              </td>
              <td>
                {{ dateUTCToLocalFunc(user.loginDate) }}
              </td>
            </tr>
          </tbody>
        </table>
        <div class="row">
          <div class="col-md-12 mb-3 text-center">
            <button
              @click="deleteProfilePrepare"
              type="button"
              class="btn button-default btn-danger">{{ content.deleteProfile }}
            </button>
          </div>
        </div>
      </div>
      <div class="mt-3">
        <span @click="terms" class="bolder pointer noselect">{{ content.agreementTerms }}</span>
      </div>
      <div class="mt-3">
        <span @click="privacyPolicy" class="bolder pointer noselect">{{ content.privacyPolicy }}</span>
      </div>
    </div>
  </div>
</template>

<script>

import { userController } from '../../services/apiService';
import { dateUTCToLocal } from '../../services/workService';

export default {
  data () {
    return {
      user: null,
      userImage: null,
      absent: '-',

      content: {
        title: 'Profile',
        name: 'Name',
        wins: 'Wins',
        defeats: 'Defeats',
        registrationDate: 'Registration date',
        loginDate: 'Authorization date',
        deleteProfile: 'Delete profile',
        deleteProfileCompleted: 'Profile was removed',
        agreementTerms: 'Terms and conditions',
        privacyPolicy: 'Privacy policy',
      },
    }
  },
  components: {
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.setUser();
    },
    setUser() {
      userController.getUserHttpGet()
        .then((data) => {
          this.user = data;
        });
    },
    dateUTCToLocalFunc(date) {
      return dateUTCToLocal(date);
    },
    terms() {
      this.$router.push('/terms');
    },
    privacyPolicy() {
      this.$router.push('/privacy-policy');
    },
    deleteProfilePrepare() {
      const approveModalItem = {
        content: this.content.deleteProfile,
        approveAction: () => { this.deleteProfile() },
        cancelAction: null,
      };
      this.$store.dispatch('setApproveModal', approveModalItem);
    },
    deleteProfile() {
      userController.deleteUserAccountHttpDelete()
        .then(() => {
          this.user = null;
          const notification = {
            content: this.content.deleteProfileCompleted,
          };
          this.$store.dispatch('setNotification', notification);
          this.$store.dispatch('logout');
          this.$router.push('/');
        })
        .catch((e) => console.log('tetete ', e));
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
