import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import { RouteTypes } from '../consts/routeTypes';

import Home from '@/views/home/Home';
import Registration from '@/views/registration/Registration';
import Login from '@/views/login/Login';
import Activation from '@/views/activation/Activation';
import PasswordChange from '@/views/passwordChange/PasswordChange';
import Profile from '@/views/profile/Profile';
import GameArea from '@/views/gameArea/GameArea';

// Lazy Loaded components
const NotFound = () => import('@/views/notFound/NotFound');
const Terms = () => import('@/views/terms/Terms');
const PrivacyPolicy = () => import('@/views/privacyPolicy/PrivacyPolicy');

const router = createRouter({
  history: process.env.VUE_APP_TYPE === 'cordova' ? createWebHashHistory() : createWebHistory(),
  routes: [
    { path: '/', name: 'Home', component: Home, meta: { type: RouteTypes.default } },
    { path: '/*', name: 'NotFound', component: NotFound, meta: { type: RouteTypes.default } },
    { path: '/terms', name: 'Terms', component: Terms, meta: { type: RouteTypes.default } },
    { path: '/privacy-policy', name: 'PrivacyPolicy', component: PrivacyPolicy, meta: { type: RouteTypes.default } },
    { path: '/registration', name: 'Registration', component: Registration, meta: { type: RouteTypes.unauthorized } },
    { path: '/login', name: 'Login', component: Login, meta: { type: RouteTypes.unauthorized } },
    { path: '/activation', name: 'Activation', component: Activation, meta: { type: RouteTypes.unauthorized } },
    { path: '/password-change', name: 'PasswordChange', component: PasswordChange, meta: { type: RouteTypes.default } },
    { path: '/profile', name: 'Profile', component: Profile, meta: { type: RouteTypes.authorized } },
    { path: '/game-area', name: 'GameArea', component: GameArea, meta: { type: RouteTypes.authorized } },
  ]
});

export default router;
