<template>
  <div v-if="game">
    <div class="game-block">
      <div class="bolder">
        <GameTimer ref="GameTimer"></GameTimer>
      </div>
      <div class="game-table">
        <div class="text-center">
          <span>Round </span>
          <span>{{ game.round }}</span>
        </div>
        <div class="text-center">
          <span>{{ gameUserStatus }}</span>
        </div>
        <div v-if="game.round > 0" class="game-info text-center">
          <div v-if="userCardAction">
            <span>Action: </span>
            <span>{{ userCardAction }}</span>
            <span
              @click="cardActionClear"
              class="action-cancel-item">
              <img class="action-icon" src="@/assets/icons/close-icon.svg">
            </span>
          </div>
          <div v-else-if="isCardUpdateActive">
            <span class="bolder complete-step" @click="gameRoundComplete">Complete round</span>
            <span class="ml-3">{{ userCardsUpdateActiveCount }}/{{ gameCardsUpdate }}</span>
          </div>
          <div v-else-if="isCardMoveActive">
            <span class="bolder complete-step" @click="gameMoveComplete">Complete move</span>
            <span class="ml-2 bolder">
              <span>shows</span>
              <span class="ml-1">
                <img v-if="isUserCardShowActive" class="info-icon" src="@/assets/icons/check-gray-icon.svg">
                <img v-else class="info-icon" src="@/assets/icons/check-icon.svg">
              </span>
            </span>
            <span class="ml-2 bolder">
              <span>actions</span>
              <span class="ml-1">
                <img v-if="isUserCardActionActive" class="info-icon" src="@/assets/icons/check-gray-icon.svg">
                <img v-else class="info-icon" src="@/assets/icons/check-icon.svg">
              </span>
            </span>
          </div>
        </div>
        <div class="user-block">
          <GameUser :user="userEnemy"></GameUser>
        </div>
        <div>
          <GameCardList
            :cards="userEnemyCards"
            @cardEvent="cardEnemyEvent">
          </GameCardList>
        </div>
        <div class="game-user-line mt-3 mb-3"></div>
        <div>
          <GameCardList
            :user="user"
            :cards="userCards"
            :isUserContext="true"
            @cardEvent="cardUserEvent">
          </GameCardList>
        </div>
        <div class="user-block">
          <GameUser :user="user"></GameUser>
        </div>
      </div>
    </div>

    <PickerBoxModal
      ref="PickerBoxModal"
      :options="pickerBoxOptions">
    </PickerBoxModal>

    <GameCardUpdateModal
      ref="GameCardUpdateModal"
      v-if="isCardUpdateActive"
      :card="userCardSelected"
      @cardUpdate="gameCardUpdate">
    </GameCardUpdateModal>

  </div>
</template>

<script>

import GameTimer from './GameTimer';
import GameUser from './GameUser';
import GameCardList from './GameCardList';
import PickerBoxModal from './modals/PickerBoxModal';
import GameCardUpdateModal from './modals/GameCardUpdateModal';
import { GameUserStatus } from '../../consts/gameUserStatus';
import { GameCardActionTyes } from '../../consts/gameCardActionTypes';
import { gameHub } from '../../services/hubService';

export default {
  data () {
    return {
      game: null,
      user: null,
      userEnemy: null,
      userCards: [],
      userEnemyCards: [],

      gameCardsShow: 1,
      gameCardsUpdate: 1,
      userCardSelected: null,
      userCardActionType: null,
      pickerBoxOptions: [],
    }
  },
  components: {
    GameTimer,
    GameUser,
    GameCardList,
    PickerBoxModal,
    GameCardUpdateModal,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  created() {
    this.init();
  },
  computed: {
    getUser() {
      return this.$store.getters.getUser;
    },
    gameHubConnection() {
      return this.$store.getters.getConnection;
    },
    gameUserStatus() {
      if (this.user.status === GameUserStatus.moveWait) {
        return 'Move waiting';
      }
      if (this.user.status === GameUserStatus.moveActive) {
        return 'Move active';
      }
      if (this.user.status === GameUserStatus.moveCompleted) {
        return 'Move completed';
      }
      if (this.user.status === GameUserStatus.roundUpdateActive) {
        return 'Round update active';
      }
      if (this.user.status === GameUserStatus.roundUpdateCompleted) {
        return 'Round update completed';
      }
      return '-';
    },
    isUserCardShowActive() {
      return this.userCards.filter(x => x.showCompleted).length < this.gameCardsShow;
    },
    isUserCardActionActive() {
      return this.userCards.filter(x => x.isActive && !x.actionCompleted).length > 0;
    },
    isUserCardUpdateActive() {
      return this.userCardsUpdateActiveCount < this.gameCardsUpdate;
    },
    userCardsUpdateActiveCount() {
      return this.userCards.filter(x => x.updateCompleted).length;
    },
    userCardAction() {
      if (!this.userCardActionType) {
        return null;
      }
      if (this.userCardActionType === GameCardActionTyes.damage) {
        return 'Attack card';
      }
      if (this.userCardActionType === GameCardActionTyes.restoration) {
        return 'Restoration card';
      }
      return null;
    },
    isCardUpdateActive() {
      return this.user.status === GameUserStatus.roundUpdateActive;
    },
    isCardMoveActive() {
      return this.user.status === GameUserStatus.moveActive;
    },
  },
  methods: {
    init() {
      if (!this.item) return;
      this.game = this.item;
      this.initGame();
    },
    initGame() {
      this.user = this.game.gameUsers.find(x => x.userId === this.getUser.id);
      this.userEnemy = this.game.gameUsers.find(x => x.userId !== this.getUser.id);
      this.userCards = this.user.gameCards;
      this.userEnemyCards = this.userEnemy.gameCards;
      setTimeout(() => this.gameTimerInit(), 1);
    },
    gameStartEvent(game) {
      this.game.round = game.round;
      this.game.roundStart = game.roundStart;
      this.gameTimerInit();
    },
    gameCardShowEvent(userId, card) {
      let gameCard = null;
      if (this.getUser.id === userId) {
        gameCard = this.userCards.find(x => x.id === card.id);
      } else {
        gameCard = this.userEnemyCards.find(x => x.id === card.id);
      }
      this.cardUpdate(gameCard, card);
    },
    gameUserStatusEvent(status) {
      this.user.status = status;
      this.gameTimerInit();
    },
    gameCardActionEvent(cardSource, cardTarget) {
      let gameCardSource = this.userCards.find(x => x.id === cardSource.id);
      if (!gameCardSource) {
        gameCardSource = this.userEnemyCards.find(x => x.id === cardSource.id);
      }
      this.cardUpdate(gameCardSource, cardSource);
      let gameCardTarget = this.userCards.find(x => x.id === cardTarget.id);
      if (!gameCardTarget) {
        gameCardTarget = this.userEnemyCards.find(x => x.id === cardTarget.id);
      }
      this.cardUpdate(gameCardTarget, cardTarget);
    },
    gameRoundCardUpdateEvent(card) {
      let gameCard = this.userCards.find(x => x.id === card.id);
      this.cardUpdate(gameCard, card);
    },
    gameRoundCompleteEvent(game) {
      const userSource = game.gameUsers.find(x => x.userId === this.getUser.id);
      this.user.status = userSource.status;
      for (let i = 0; i < this.userCards.length; i++) {
        let userCard = this.userCards[i];
        const userCardSource = userSource.gameCards.find(x => x.id === userCard.id);
        this.cardUpdate(userCard, userCardSource);
      }
    },
    gameRoundNewEvent(game) {
      this.game = game;
      const userContextSource = game.gameUsers.find(x => x.userId === this.getUser.id);
      const userEnemySource = game.gameUsers.find(x => x.userId !== this.getUser.id);
      this.user.status = userContextSource.status;
      this.userEnemy.status = userEnemySource.status;
      for (let i = 0; i < this.userCards.length; i++) {
        let card = this.userCards[i];
        const cardSource = userContextSource.gameCards.find(x => x.id === card.id);
        this.cardUpdate(card, cardSource);
      }
      for (let i = 0; i < this.userEnemyCards.length; i++) {
        let card = this.userEnemyCards[i];
        const cardSource = userEnemySource.gameCards.find(x => x.id === card.id);
        this.cardUpdate(card, cardSource);
      }
      this.gameTimerInit();
    },
    showCard() {
      const gameCardShowAsyncModel = {
        gameId: this.game.id,
        cardId: this.userCardSelected.id
      };
      gameHub.invoke('GameCardShowAsync', gameCardShowAsyncModel);
      this.userCardSelected = null;
    },
    damageCard() {
      this.userCardActionType = GameCardActionTyes.damage;
    },
    restorationCard() {
      this.userCardActionType = GameCardActionTyes.restoration;
    },
    gameCardUpdate(cardId) {
      const gameRoundCardUpdateAsyncModel = {
        gameId: this.game.id,
        cardId: cardId
      };
      gameHub.invoke('GameRoundCardUpdateAsync', gameRoundCardUpdateAsyncModel);
    },
    gameRoundComplete() {
      const gameRoundCompleteAsyncModel = {
        gameId: this.game.id
      };
      gameHub.invoke('GameRoundCompleteAsync', gameRoundCompleteAsyncModel);
    },
    gameMoveComplete() {
      const gameMoveCompleteAsyncModel = {
        gameId: this.game.id
      };
      gameHub.invoke('GameMoveCompleteAsync', gameMoveCompleteAsyncModel);
    },
    cardEnemyEvent(card) {
      if (!card.isActive || card.healthReal === 0) return;
      if (this.userCardActionType && this.userCardSelected) {
        const gameCardActionAsyncModel = {
          gameId: this.game.id,
          gameCardIdSource: this.userCardSelected.id,
          gameCardIdTarget: card.id,
          cardAction: this.userCardActionType,
        };
        gameHub.invoke('GameCardActionAsync', gameCardActionAsyncModel);
        this.cardActionClear();
      }
    },
    cardUserEvent(card) {
      if (card.healthReal > 0) {
        if (this.user.status === GameUserStatus.moveActive) {
          this.pickerBoxOptions = [];
          const isRestorationEvent = this.userCardSelected 
            && this.userCardSelected.isActive 
            && this.userCardActionType === GameCardActionTyes.restoration 
            && card.healthReal < card.health;
          if (this.userCardSelected && !isRestorationEvent) {
            this.cardActionClear();
          }
          if (card.isActive) {
            // show actions
            if (!this.userCardSelected && !card.actionCompleted) {
              this.userCardSelected = card;
              this.pickerBoxOptions.push({ text: 'Attack', value: 'attack', action: this.damageCard });
              this.pickerBoxOptions.push({ text: 'Restoration', value: 'restoration', action: this.restorationCard });
              this.$refs.PickerBoxModal.show();
            } else if (isRestorationEvent) {
                // restoration
                const gameCardActionAsyncModel = {
                  gameId: this.game.id,
                  gameCardIdSource: this.userCardSelected.id,
                  gameCardIdTarget: card.id,
                  cardAction: this.userCardActionType,
                };
                gameHub.invoke('GameCardActionAsync', gameCardActionAsyncModel);
                this.cardActionClear();
            }
          } else {
            if (this.isUserCardShowActive) {
              // show card
              this.userCardSelected = card;
              this.pickerBoxOptions.push({ text: 'Show Card', value: 'showcard', action: this.showCard });
              this.$refs.PickerBoxModal.show();
            }
          }
        } else if (this.user.status === GameUserStatus.roundUpdateActive && this.isUserCardUpdateActive && !card.updateCompleted) {
          // show card update
          this.userCardSelected = card;
          this.$refs.GameCardUpdateModal.show();
        }
      }
    },
    cardUpdate(cardTarget, cardSource) {
      cardTarget.type = cardSource.type;
      cardTarget.level = cardSource.level;
      cardTarget.health = cardSource.health;
      cardTarget.healthReal = cardSource.healthReal;
      cardTarget.armor = cardSource.armor;
      cardTarget.damage = cardSource.damage;
      cardTarget.restoration = cardSource.restoration;
      cardTarget.healthUpdate = cardSource.healthUpdate;
      cardTarget.armorUpdate = cardSource.armorUpdate;
      cardTarget.damageUpdate = cardSource.damageUpdate;
      cardTarget.restorationUpdate = cardSource.restorationUpdate;
      cardTarget.actionCompleted = cardSource.actionCompleted;
      cardTarget.updateCompleted = cardSource.updateCompleted;
      cardTarget.showCompleted = cardSource.showCompleted;
      cardTarget.isActive = cardSource.isActive;
      cardTarget.cardId = cardSource.cardId;
      cardTarget.gameUserId = cardSource.gameUserId;
    },
    cardActionClear() {
      this.userCardActionType = null;
      this.userCardSelected = null;
      this.pickerBoxOptions = [];
    },
    gameTimerInit() {
      this.$refs.GameTimer.init();
    },
  },
}
</script>

<style lang="scss" scoped>
.game-block {
  position: relative;
  min-height: 400px;
  padding: 20px;
  border-radius: 10px;
  background-color: #f0f0f0;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  .game-table {
    .game-user-line {
      border-bottom: 1px solid #3d4b7f8a;
    }
    .game-info {
      height: 20px;
      margin-top: 10px;
      .info-icon {
        width: 20px;
      }
    }
    .action-cancel-item {
      margin-left: 10px;
      cursor: pointer;
      .action-icon {
        width: 25px;
      }
    }
    .complete-step {
      cursor: pointer;
      background-color: #4ac73f;
      border-radius: 6px;
      padding: 2px 6px 2px 6px;
    }
  }
}
</style>
