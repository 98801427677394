<template>
  <div>
    <div class="text-center bolder">
      {{ content.title }}
    </div>
    <div class="col-md-6 offset-md-3">
      <div class="block-width-fixed">
        <div v-if="!isSuccess">
          <div>
            <InputBox
              :modelValue="email"
              @update:modelValue="email = $event"
              :content="email"
              title="Email"
              type="text"
              :isError="emailError"
              :messageError="content.emailError">
            </InputBox>
          </div>
          <div v-if="!isCodeSent">
            <div class="text-right pointer bolder noselect">
              <span @click="isCodeSent = true">{{ content.haveCode }}</span>
            </div>
            <div class="pt-3 text-center">
              <button
                @click="sendCode"
                :disabled="emailError"
                type="button"
                class="btn button-default btn-success">{{ content.sendCode }}
              </button>
            </div>
          </div>
          <div v-else>
            <InputBox
              :modelValue="code"
              @update:modelValue="code = $event"
              :title="content.code"
              type="text"
              :isError="codeError"
              :messageError="content.codeError">
            </InputBox>
            <PasswordCreate @result="passwordResult"></PasswordCreate>
            <div class="text-right pointer bolder noselect">
              <span @click="isCodeSent = false">{{ content.sendCode }}</span>
            </div>
            <div class="pt-3 text-center">
              <button
                @click="passwordChange"
                :disabled="passwordChangeError"
                type="button"
                class="btn button-default btn-success">{{ content.passwordChange }}
              </button>
            </div>
          </div>
        </div>
        <div v-else class="pt-3 text-center">
          <button
            @click="login" type="button" class="btn button-default btn-success">{{ content.login }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import InputBox from '../../components/InputBox';
import { checkEmail } from '../../services/validateService';
import { userController } from '../../services/apiService';
import PasswordCreate from '../../components/PasswordCreate';

export default {
  data () {
    return {
      email: '',
      code: '',
      password: '',
      isCodeSent: false,
      isSuccess: false,

      content: {
        title: 'Password change',
        emailError: 'Incorrect email',
        haveCode: 'I have code',
        sendCode: 'Send code',
        code: 'Code',
        codeError: 'Code is required',
        passwordChange: 'Change password',
        login: 'Login',
        passwordChangeRequestSent: 'A password reset email has been sent',
        passwordChanged: 'Password was changed successfully',
      },
    }
  },
  components: {
    InputBox,
    PasswordCreate,
  },
  created() {
    this.init();
  },
  computed: {
    emailError() {
      if (checkEmail(this.email)) {
        return false;
      }
      return true;
    },
    codeError() {
      if (this.code.length) {
        return false;
      }
      return true;
    },
    passwordError() {
      if (this.password.length) {
        return false;
      }
      return true;
    },
    passwordChangeError() {
      if (this.codeError || this.passwordError) {
        return true;
      }
      return false;
    },
  },
  methods: {
    init() {
      this.email = this.$route.query.email;
    },
    sendCode() {
      userController.passwordChangeRequestHttpPost(this.email)
        .then(() => {
          this.isCodeSent = true;
          const notification = {
            content: this.content.passwordChangeRequestSent,
          };
          this.$store.dispatch('setNotification', notification);
        });
    },
    passwordResult(password) {
      this.password = password;
    },
    passwordChange() {
      userController.passwordChangeApproveHttpPost(this.email, this.password, this.code)
        .then(() => {
          this.isSuccess = true;
          const notification = {
            content: this.content.passwordChanged,
          };
          this.$store.dispatch('setNotification', notification);
        });
    },
    login() {
      this.$router.push({ path: '/login', query: { email: this.email } });
    },
  },
}
</script>

<style scoped>
</style>
